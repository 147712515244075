.Fotter{
    height: auto;
    background-color: #0d203c;
    width: 100%;
    padding:10px;
}

.footerLogo{
    width: 10vw;
}

.Fotter h1{
    color: white;
    text-align: center;
    font-weight: 600;
    font-size: 3vw;
}

.Fotter h4{
    color: rgba(255, 255, 255, 0.74);
    font-family: "Italiana", serif; 
    font-size: 1.8vw;

}


#centerDiv{
    text-align: center;
}

.Fotter .col{
    text-align: left;
    color: white;
    font-size: 2.2vw;
    font-weight: 200;
    letter-spacing: 1px;
    color: rgba(240, 24 8, 255, 0.852);
    line-height: 1;
}

.socialLinks{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 50%;
    margin: auto;
    font-size: 2.5vw;
}

#address{
    font-size: 2.2vw;
    font-weight:200;
    letter-spacing: 1px;
    line-height: 1.2;
    color: rgba(240, 248, 255, 0.852);
}

.fotterContainer{
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
}

.insideFotterContainer{
display: flex;
justify-content: space-around;   
width: 90%;
margin: auto;
margin-bottom: 50px;
}

.number{
    color: white;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
}

#centerDiv i{
    color: white;
}



@media only screen and (max-width: 768px) {

    .footerLogo{
        width: 25vw;
    }

    .Fotter h1{
        color: white;
        text-align: center;
        font-weight: 600;
        font-size: 6vw;
    }

    .Fotter h4{
        font-size: 5vw;
    }

    .fotterContainer{
        display: flex;
        margin-bottom: 50px;
        flex-direction: column;
        gap: 10px;
    }

    .insideFotterContainer{
        display: flex;
        justify-content: space-between;  
        flex-direction: column; 
        width: 90%;
        margin: auto;
        margin-bottom: -15px;
        margin-top: -22px;
        }
        

    .Fotter .col{
        text-align: center;
        color: white;
        font-size: 4.2vw;
        font-weight: 200;
        letter-spacing: 1px;
        color: rgba(240, 24 8, 255, 0.852);
        line-height: 0.8;
        margin-bottom: 20px;
    }

    .socialLinks{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        width: 50%;
        margin: auto;
        font-size: 8.5vw;
        margin-top: -27px;
    }
    

    #address{
        font-size: 4.2vw;
        font-weight:200;
        letter-spacing: 1px;
        line-height: 1.2;
        color: rgba(240, 248, 255, 0.852);
        margin-top: -18px;
    }
}

