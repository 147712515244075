/* For all elements */
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

* {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}

body {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.getInTouch{
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    right: 22px;
    font-size: 1.4vw;
}

@media only screen and (max-width: 768px) {
    .getInTouch{
        position: fixed;
        bottom: 20px;
        z-index: 1000;
        right: 22px;
        font-size: 3.4vw;
    }
}