.parentMythDiv{
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.904);
    width: 100%;
    height: 100vh;
    z-index: 100;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mythDiv{
    width: 80%;
    height: 90%;
    background-color: rgba(215, 251, 254, 0.929);
    backdrop-filter: blur(15px); 
    border: 4px solid white;
}

.mythDiv h1{
    text-align: center;
    font-weight: 400;
    font-size: 3vw;
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
    font-family: "Playfair Display", serif;
}

.mythDiv h4{
width: 80%;
margin: auto;
margin-bottom: 20px;
font-weight: 400;
color: rgb(61, 61, 61);
margin-top: 20px;
font-size: 2vw;
}

.mythDiv h2{
    font-family: "Dancing Script", cursive;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 2vw;
}

.cross{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2vw;
    cursor: pointer;
}

.closebtn{
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size:1.8vw;
}

@media only screen and (max-width: 768px) {

    .parentMythDiv{
        height: 100vh;
        overflow-y: auto;
        display: block;
    }

    .mythDiv{
        height: auto;
        margin: auto;
        margin-bottom: 20p;
        padding-bottom: 50px;
        width: 100%;
    }

    .mythDiv h1{
        font-size: 6vw;
    }

    .mythDiv h4{
        width: 80%;
        margin: auto;
        margin-bottom: 20px;
        font-weight: 400;
        color: rgb(61, 61, 61);
        margin-top: 20px;
        font-size: 4vw;
        }
        
        .mythDiv h2{
            font-family: "Dancing Script", cursive;
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: 6vw;
        }
        
        .cross{
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 6vw;
            cursor: pointer;
        }

        .closebtn{
            position: absolute;
            left: 20px;
            bottom: 10px;
            font-size:4vw;
        }
        
}