@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.firstDiv{
    height: 100vh;
    width: 100%;
    background-color: aqua;
    background-image: url("../images/erawat.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
}

.textDiv{
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.539);
    position: absolute;
    top: 0px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.name{
    color:white;
    font-size: 10vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.continueBtn{
    width: auto;
    margin:0px auto;
    font-size: 1.8vw;
    padding:0px 20px;
}

@media only screen and (max-width: 768px) {
    .continueBtn{
        width: auto;
        margin:0px auto;
        font-size: 4vw;
        padding:0px 20px;
    }
    
}