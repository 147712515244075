.FourthSection{
    height: auto;
    width: 100%;
    background-color: #191919;
    padding:0px 50px;
    padding-bottom: 100px;
    position: relative;
    margin-top: -.5px;
    border-top: 1px solid transparent;
}

.FourthSection h1{
    color: white;
    font-size: 3vw;
    font-weight: 100;
    text-align: center;
}
 
.artistpic{
    display: flex;
    justify-content: center;
}

.ArtistPhoto{
    min-height: 450px;
    min-width:  450px;
    height: 32vw;
    width: 32vw;
    background-color: white;
    margin-top: 50px;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
    background-image: url('../images/artist.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 100%;
}

.aboutArtist{
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
}

#aboutArtisth{
    text-align: left;
    font-size: 3vw;
    font-family: "Italiana", serif;

}

#aboutArtisth span{
    font-family: "Dancing Script", cursive;
}


.aboutArtist h4{
    color: rgb(104, 104, 104);
    font-size: 1.5vw;
}

.aboutArtist p{
    color: rgb(186, 186, 186);
    font-style: italic;
    font-size: 1.8vw;
}

.slider{
    margin-top: 10px;
}

.slides{
    /* border: 1px solid white; */
    min-height: 500px;
    height: 90vh;
    padding:50px;
}

.insideSlide{
    height: 100%;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);

}

.slidePhoto1{
    height: 80%;
    width: 100%;
    background-image: url('../images/WhatsApp\ Image\ 2024-05-20\ at\ 14.43.30_eca9fa46.jpg');
    background-position: center;
    background-size: cover;
    /* box-shadow: 1px 10px 20px rgb(0, 0, 0); */
}

.slidePhoto2{
    height: 80%;
    width: 100%;
    background-image: url('../images/painting1.png');
    background-position: center;
    background-size: cover;
    /* box-shadow: 1px 10px 20px rgb(0, 0, 0); */

}

.slidePhoto3{
    height: 80%;
    width: 100%;
    background-image: url('../images/painting2.png');
    background-position: center;
    background-size: cover;
    /* box-shadow: 1px 10px 20px rgb(0, 0, 0); */

}

.slidePhoto6{
    height: 80%;
    width: 100%;
    background-image: url('../images/ganeshJiMural.png');
    background-position: center;
    background-size: cover;
    /* box-shadow: 1px 10px 20px rgb(0, 0, 0); */

}

.slidePhoto4{
    height: 80%;
    width: 100%;
    background-image: url('../images/erawat.jpg');
    background-position: center;
    background-size: cover;
    /* box-shadow: 1px 10px 20px rgb(0, 0, 0); */

}

.slidePhoto7{
    height: 80%;
    width: 100%;
    background-image: url('../images/saras.jpg');
    background-position: center;
    background-size: cover;
}



.slidePhoto8{
    height: 80%;
    width: 100%;
    background-image: url('../images/flower.jpg');
    background-position: center;
    background-size: cover;
}

.slidePhoto9{
    height: 80%;
    width: 100%;
    background-image: url('../images/bestOfWaste1.jpg');
    background-position: center;
    background-size: cover;
}

.slidePhoto5{
    height: 80%;
    width: 100%;
    background-image: url('../images/painting3.jpg');
    background-position: center;
    background-size: cover;
    /* box-shadow: 1px 10px 20px rgb(0, 0, 0); */

}

.slidePhoto10{ 
    height: 80%;
    width: 100%;
    background-image: url('../images/peta1.jpg');
    background-position: center;
    background-size: cover;
}

.slidePhoto11{ 
    height: 80%;
    width: 100%;
    background-image: url('../images/magzineIllustration.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: grey;
}

.slidePhoto11{ 
    height: 80%;
    width: 100%;
    background-image: url('../images/magzineIllustration.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgb(222, 222, 222);
}

.slidePhoto12{ 
    height: 80%;
    width: 100%;
    background-image: url('../images/magzineIllustration1.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgb(222, 222, 222);
}


.slideInside{
    padding-top:2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slideInside h4{
    font-size: 1.8vw;
    

}

.slideInside p{
    line-height: 1;
    text-align: center;
    margin-top: -4px;
    font-size: 1.2vw;
    font-weight: 600;
    color: rgb(101, 101, 101);
}

.custom-shape-divider-bottom-1716299990 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1716299990 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 48px;
}

.custom-shape-divider-bottom-1716299990 .shape-fill {
    fill: #0d203c;
}

.studentDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.studentMain{
    background-image: url('../images/studentm.jpg');
    height: 38vw;
    width: 50vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 50px;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
    
}

.studentPhoto1{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-image: url('../images/student1.jpg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);

}

.studentPhoto2{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-image: url('../images/student5.jpg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}

.studentPhoto3{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-image: url('../images/student3.jpg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}

.studentPhoto4{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-color: white;
    background-image: url('../images/student4.jpeg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}

.studentPhoto5{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-color: white;
    background-image: url('../images/student6.jpg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}

.studentPhoto6{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-color: white;
    background-image: url('../images/student4.jpeg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}


.studentPhoto7{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-color: white;
    background-image: url('../images/watercolor1.jpg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}

.studentPhoto8{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-color: white;
    background-image: url('../images/student8.jpg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}

.studentPhoto9{
    min-height: 350px;
    min-width: 300px;
    height: 70vh;
    width: 26vw;
    background-color: white;
    background-image: url('../images/student9.jpg');
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    box-shadow: 1px 10px 20px rgb(0, 0, 0);
}



.row{
    margin-top: 40px;
}

.creationHeading{
    text-align: center;
    font-weight: 200;
    font-family: "Italiana", serif; 
}

#workDoneInClass h1{
    text-align: center;
    font-weight: 200;
    font-family: "Italiana", serif; 
}

.numberOfStudentsTrained{
    margin-top: 80px;
}

.numberOfStudentsTrained h1{
    color: #888888;
}

.numberOfStudentsTrained span{
    font-size: 10vw;
    color: rgb(250, 250, 250);
}


@media only screen and (max-width: 768px) {
    .ArtistPhoto{
        height: 350px;
        min-height: 386px;
        min-width: 360px;
    }

    #aboutArtisth{
        text-align: center;
        font-size: 8vw;
        font-family: "Italiana", serif;
        margin-top: 28px;    
    }

    .aboutArtist h4{
        color: rgb(104, 104, 104);
        font-size: 4.5vw;
        text-align: center;
    }

    .aboutArtist p{
        color: rgb(186, 186, 186);
        font-style: italic;
        font-size: 5.5vw;
        text-align: center;
    }

    .FourthSection h1{
        font-size: 6vw;
    }

    .slides{
        height: 500px;
        padding:0px;
    }

    .slideInside h4{
        font-size: 6vw;
    }
    
    .slideInside p{
        line-height: 1;
        text-align: center;
        margin-top: -4px;
        font-size: 4vw;
    }
    

    .row{
        margin-top: 0px;
    }

    .studentMain{
        height: 61vw;
        width: 100%;
        margin-bottom: 0px;
    }

    .studentDiv{
       margin-top: 20px;
    }

    .studentPhoto1{
        min-height: 350px;
        min-width: 300px;
        height: 38vh;
        width: 26vw;
        background-image: url('../images/student1.jpg');
        background-position: center;
        background-size: cover;
        border: 4px solid white;
        box-shadow: 1px 10px 20px rgb(0, 0, 0);
    }

    .studentPhoto2 ,.studentPhoto3,.studentPhoto4,.studentPhoto5,.studentPhoto6,.studentPhoto7,.studentPhoto8,.studentPhoto9{
        min-height: 350px;
        min-width: 300px;
        height: 38vh;
        width: 26vw;
    }


    .studentPhoto7{
   
        background-color: white;
        background-image: url('../images/watercolor1.jpg');
        background-position: center;
        background-size: cover;
        border: 4px solid white;
        box-shadow: 1px 10px 20px rgb(0, 0, 0);
    }
    
    .studentPhoto8{
        background-color: white;
        background-image: url('../images/student8.jpg');
        background-position: center;
        background-size: cover;
        border: 4px solid white;
        box-shadow: 1px 10px 20px rgb(0, 0, 0);
    }
    
    .studentPhoto9{
        background-color: white;
        background-image: url('../images/student9.jpg');
        background-position: center;
        background-size: cover;
        border: 4px solid white;
        box-shadow: 1px 10px 20px rgb(0, 0, 0);
    }

    
    .numberOfStudentsTrained span{
        font-size: 18vw;
        color: rgb(250, 250, 250);
    }

    .creationHeading{
        text-align: center;
        font-weight: 200;
        font-family: "Italiana", serif; 
        font-size: 6vw;
    }  
    
}