.navbar{
    height: 7vh;
    width: 100%;
    position: fixed;
    z-index: 100;
    backdrop-filter: blur(5px); 
    background-color: rgba(255, 255, 255, 0.5);  
    display: flex;
    padding: 0px 4vw;
    transition: all 0.5s ease-in-out;
}

.navbarPhone{
    height: 40px;
    width: 100%;
    position: fixed;
    z-index: 100;
    backdrop-filter: blur(5px); 
    background-color: rgba(255, 255, 255, 0.5);  
    display: flex;
    padding: 0px 4vw;
    transition: all 0.5s ease-in-out;
}

.navFull{
    height: 90vh;
    /* background-color: red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.logoDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

#logoImg{
    width: 3vw;
    margin-top: -7px;
}

.navFull h4{
    font-size:12vw;
    text-align: center;
    font-weight: 600;
}

.navbar h4{
    font-family: "Dancing Script", cursive;
    font-size: 1.8vw;
}

#navs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

#navs h5{
    font-weight: 400;
    font-size: 1.2vw;
    color: rgb(100, 102, 105);
    cursor: pointer;
}

.upperBar{
    transform: rotate(45deg);
    transition: all 0.5s ease-in-out;
    position: relative;
    top: 0px;
}

.lowerBar{
    transform: rotate(-45deg);
    transition: all 0.5s ease-in-out;
    position: relative;
    top: -5px;
}

.menuBar{
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

@media only screen and (max-width: 768px) {

    #logo{
        font-size: 4vw;
    }

    #logoImg{
        width: 8vw;
        margin-top: -7px;
    }

    #navs h5{
        display: none;
    }

    .menuBar{
        display: block;
    }

    .navbar h4{
        font-family: "Dancing Script", cursive;
        font-size: 10vw;
        font-weight: 700;
    }    

    .navbar{
        height: 40px;
    }

}