@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.topDiv{
    padding: 50px;
    height: 100vh;
    background: linear-gradient(to bottom, rgb(223, 247, 255), white);
    position: relative;
}

.topBackground{
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    left: 0px;
    /* background-image: url('../images/erawat.jpg'); */
    z-index: 0;
}

.topDiv h1{
    width: 50%;
    font-family: "Playfair Display", serif;
    font-size: 5vw;
    color: #0d203c;
}

.since{
    color: orangered;
    font-weight: 400;
    font-size: 2vw;
    font-family: "Dancing Script", cursive;

}

.since span{
    font-family: "Playfair Display", serif;
    font-size: 2.5vw;

}

.photo1{
    height:70vh;
    width: 24vw;
    border: 5px solid rgb(255, 255, 255);
    margin-right: 10px;
    position: absolute;
    bottom:5vh;
    right: 27vw;
    box-shadow: 1px 10px 10px grey;
    background-image: url('../images/painting1.png');
    background-position: center;
    background-size: cover;
}

.photo2{
    height: 75vh;
    width: 25vw;
    border: 5px solid rgb(255, 255, 255);
    position: absolute;
    right: 2vw;
    bottom: 5vh;
    box-shadow: 1px 10px 10px grey;
    background-image: url('../images/WhatsApp\ Image\ 2024-05-20\ at\ 14.43.30_eca9fa46.jpg');
    background-position: center;
    background-size: cover;
}

.photoPhone1{
    display: none;
}

.photoPhone2{
    display: none;
}

.topDiv button{
    font-size: 1.2vw;
}

@media only screen and (max-width: 768px) {
    .topDiv{
        padding: 50px;
        /* border: 1px solid; */
        height: auto;
        background: linear-gradient(to bottom, rgb(223, 247, 255), white);
        position: relative;
    }
    
    .topDiv h1{
        width: 100%;
        font-family: "Playfair Display", serif;
        font-size: 6vw;
        color: #0d203c;
        text-align: center;
    }

    .since{
        color: orangered;
        font-weight: 400;
        font-size: 6vw;
        font-family: "Dancing Script", cursive;
        text-align: center;
        margin-bottom: -5px;
    
    }
    
    .since span{
        font-family: "Playfair Display", serif;
        font-size: 6.5vw;
    
    }

    .photo1{
       display: none;
    }

    .photo2{
       display: none;
    }

    .photoPhone1{
        display: block;
        background-color: rebeccapurple;
        height: 50vh;
        width: 95%;
        margin: auto;
        margin-top: 30px;
        box-shadow: 1px 10px 10px grey;
        background-image: url('../images/WhatsApp\ Image\ 2024-05-20\ at\ 14.43.30_eca9fa46.jpg');
        background-position: center;
        background-size: cover;
        border: 4px solid white;
        transform: rotate(-6deg);
    }
    
    .photoPhone2{
        display: block;
        background-color: rebeccapurple;
        height: 50vh;
        width: 95%;
        margin: auto;
        margin-top: 28px;
        box-shadow: 1px 10px 10px grey;
        background-image: url('../images/painting1.png');
        background-position: center;
        background-size: cover;
        border: 4px solid white;
        transform: rotate(6deg);
    }
    
    .topBtns{
        margin: auto;
        text-align: center;
        display: flex;
        justify-content: space-between;
    }

    .topBtns bottom{
        margin: auto;
    }

    #levelUp{
        color: #0d203c;
        font-weight: 800;
        z-index: 10;
        position: absolute;
        bottom: -20px;
        font-family: "Dancing Script", cursive;
        left: 35%;
    }

    .topDiv button{
        font-size: 3.2vw;
    }
    
}