@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Pacifico&display=swap');

.secondSection{
    margin-top: 50px;
    background-color: #0d203c;
    height: auto;
    width: 100%;
    padding: 20px;
    padding-top: 60px;
    padding-bottom: 150px;
    position: relative;
}

.phoneMargine{
    display: none;
}

#levelUp{
    color: #0d203c;
    font-weight: 800;
    z-index: 10;
    position:absolute;
    bottom: -150px;    
    font-family: "Dancing Script", cursive;
    font-size: 1.6vw;
}

.secondSection h1{
    color: white;
    font-size: 3vw;
    font-weight: 200;
    margin-bottom:10px;
    text-align: center;
    font-family: "Italiana", serif; 
}

.card{
    height: 70vh;
    min-height: 450px;
    width: 30vw;
    min-width: 320px;
    margin: auto;
    box-shadow: 1px 1px 15px rgb(0, 0, 0);
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 25px;
}

.interestedBtn{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 2px 10px;
    box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.779);
}

.card p{
    
}

.cardImg{
    background-image: url('../images/pencilColor.jpg');
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg2{
    background-image: url('../images/watercolor2.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
}

 
.cardImg3{
    background-image: url('../images/acrelic.jpg');
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg4{
    background-image: url('../images/anatomy.jpg');
    background-position: bottom;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg5{
    background-image: url('../images/student1.jpg');
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg6{
    background-image: url('../images/painting2.png');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg7{
    background-image: url('../images/landscape1.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg8{
    background-image: url('../images/stillLife.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg9{
    background-image: url('../images/designLogo1.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 55%;
}

.cardImg10{
    background-image: url('../images/ganeshJiMural.png');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.cardImg11{
    background-image: url('../images/illustration.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 55%;
}

.card h4{
    font-size: 2vw;
}

.card p{
    font-size: 1.4vw;
}

.card button{
    font-size: 1.2vw;
}

.courseDiv{
    margin-bottom: 50px;
}

.moreCourses{
    margin: auto;
    text-align: center;
    margin-top: 10px;
    font-size: 1.4vw;
}

.custom-shape-divider-top-1716263409 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1716263409 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 141px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1716263409 .shape-fill {
    fill: #FFFFFF;
}


.custom-shape-divider-bottom-1716266480 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1716266480 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 88px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1716266480 .shape-fill {
    fill: rgb(236, 236, 236);
}


.secondSection .row{
    margin-bottom: 18px;
}

.showMore, .showLess {
    transition: height 5s ease-in-out;
    /* overflow: hidden; */
}

.showMore{
    height:auto;
    transition: 2s ease-in-out;
}

.showLess{
    height: auto;   
    transition: 2s ease-in-out;
}

.courseContent{
    padding: 20px;
}

.courseContent h4{
    font-weight: 400;
}

.topicBtns{
    background-color: #ececec;
    padding: 2px 5px;
    font-size: 14px;
}

.pricing{
    background-color: #dbdbdb;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 0px 20px 0px 20px;  
    margin-top: -4px;
    height: 6.5%;
    padding-top: 12px;
    position: absolute;
    bottom: 5px;
}

.pricing p{
    font-family: "Dancing Script", cursive;
    font-size: 1.4vw;
    letter-spacing: 2px;
}

.pricing p span{
    color: rgb(104, 104, 104);
}

#price{
    color: #0d203c;
    font-family: "Italiana", serif; 
    font-weight: 800;
    letter-spacing: 1px;
}

#priceR{
    color: #ff8800;
    font-family: "Italiana", serif; 
    font-weight: 800;
    letter-spacing: 1px;
    text-decoration-line: line-through;
}

.see{
    color: #ffffff;
    position: absolute;
    right: 60px;
    bottom: 60px;
    font-weight: 800;
    font-family: "Dancing Script", cursive;
    font-size: 1.8vw;
}

.movingHeading{
    margin: auto;
    width: 100vw;
    white-space: nowrap;
    overflow-x: auto;
    position: absolute;
    left: 0;
    margin-bottom: 20px;
}

.movingHeading h1{
    display: inline-block;
    animation-name: scroll;
    animation-duration: 18s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.moreCoursesDiv{
    margin-top: 20vh;
}



@keyframes scroll{
    0%{
        transform:translateX(0);
    }
    100%{
        transform:translateX(-100%);
    }
}

@media only screen and (max-width: 768px) {

.secondSection h1{
    color: white;
    font-size: 6vw;
    font-weight: 200;
    margin-bottom:10px;
    text-align: center;
    font-family: "Italiana", serif; 
    }

    
#levelUp{
    color: #0d203c;
    font-weight: 800;
    z-index: 10;
    position:absolute;
    bottom: -150px;    
    font-family: "Dancing Script", cursive;
    font-size: 5vw;
}
   
    .card{
        height: 400px;
        width: 290px;
        margin: auto;
        box-shadow: 1px 1px 15px rgb(0, 0, 0);
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 25px;
    }
    
    .card h4{
        font-size: 6vw;
    }

    .card p{
        font-size: 4vw;
    }

    .card button{
        font-size: 3.2vw;
    }
    
    .moreCourses{
        font-size: 3.2vw;
    }

    .pricing p{
        font-family: "Dancing Script", cursive;
        font-size: 18px;
        letter-spacing: 0px;
    }

    .see{
        color: #ffffff;
        position: absolute;
        text-align: center;
        bottom: 80px;
        left: 15%;
        font-weight: 800;
        font-family: "Dancing Script", cursive;
        font-size: 24px;
    }

    .moreCoursesDiv{
        margin-top: 88px;
    }
    
    .Margine{
        display: none;
    }

    .phoneMargine{
        display: block;
    }

    .custom-shape-divider-top-1716263409 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }
    
    .custom-shape-divider-top-1716263409 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 50px;
        transform: rotateY(180deg);
    }
    
    .custom-shape-divider-top-1716263409 .shape-fill {
        fill: #FFFFFF;
    }

    .custom-shape-divider-bottom-1716266480 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 50px;
        transform: rotateY(180deg);
    }

}