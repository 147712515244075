.ThirdSection{
    height: 120vh;
    width: 100%;
    position: relative;
    background-color: rgb(236, 236, 236);
}

.ThirdSection h1{
    text-align: center;
    font-weight: 200;
    font-family: "Italiana", serif; 
    font-size: 3vw;
}

.kidsDiv{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;   
}

.kidsContentDiv{
    width: 50%;
}


.kidsImage{
    height: 35vw;
    width: 35vw;
    background-image: url('../images/kidPhotoReal.jpg');
    background-size: cover;
    background-position: center;
    border: 2px solid white;
    box-shadow: 1px 10px 10px grey;
    border-radius: 52% 48% 49% 51% / 39% 41% 59% 61% ;
    margin-top: -30px;

}

#kidsContent{
    text-align: left;
    font-size: 5vw;
    color: #0d203c;
}

.kidsContentDiv button{
    font-size: 1.4vw;
}

.seatsLeft{
    margin-top: 10px;
    padding: 5px;
    text-align: center;
    border-radius: 50px;
    color: rgb(255, 117, 67);
    font-weight: 700;
    font-size: 1.4vw;
}

.custom-shape-divider-bottom-1716275206 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1716275206 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 95px;
}

.custom-shape-divider-bottom-1716275206 .shape-fill {
    fill: #191919;
}

@media only screen and (max-width: 768px) {
    
    .ThirdSection{
        height: auto;
        width: 100%;
        position: relative;
        background-color: rgb(236, 236, 236);
        padding-bottom: 100px;
    }

    .ThirdSection h1{
        text-align: center;
        font-weight: 200;
        font-family: "Italiana", serif; 
        font-size: 6vw;
    }

    .kidsDiv{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height:auto;
    }

    .kidsImage{
        height: 106vw;
        width: 95vw;
        background-image: url('../images/kidPhotoReal.jpg');
        background-size: cover;
        background-position: center;
        border: 5px solid white;
        box-shadow: 1px 10px 10px grey;
        border-radius: 52% 48% 49% 51% / 39% 41% 59% 61% ;
        margin-top: -30px;
    }

    .kidsContentDiv{
        width: 90%;
    }

    #kidsContent{
        text-align: center;
        font-size: 16vw;
        color: #0d203c;
    }

    .kidsContentDiv button{
        font-size: 3.2vw;
    }

    .seatsLeft{
        font-size: 4.4vw;
    }
    

    .courseContentHeading{
        text-align: center;
        margin-top: 50px;
    }

    .custom-shape-divider-bottom-1716275206 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 65px;
    }
}